import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getUpcomingTripsList } from '@/redux/printLabelsFc/printLabelsFc.actions';
import validCheck from '@/assets/valid_check.png';
import { Loader } from '@/components';

function PrintLabelsFc() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { data: trips = [], status } = useSelector(
    (state) => state.printLabelsFc
  );
  const [currentDay, setCurrentDay] = useState(0);
  const [days, setDays] = useState([]);
  const [isReverseOrder, setIsReverseOrder] = useState(false); // New state for toggling order

  useEffect(() => {
    dispatch(getUpcomingTripsList());
  }, [dispatch]);

  const filterTrips = (trip) =>
    trip.status === 'picking' || trip.ponfigueStatus !== 'NOT_REGISTERED';
  useEffect(() => {
    if (Array.isArray(trips) && trips.length > 0) {
      const filteredTripsForDays = trips.filter((trip) => filterTrips(trip));
      const uniqueDays = [
        ...new Set(filteredTripsForDays.map((trip) => trip.deliveryDay))
      ];
      setDays(uniqueDays);
    }
  }, [trips]);

  const filteredTrips = Array.isArray(trips)
    ? trips.filter(
        (trip) => trip.deliveryDay === days[currentDay] && filterTrips(trip)
      )
    : [];

  const layout = () => {
    const tripsToDisplay = isReverseOrder
      ? [...filteredTrips].reverse()
      : filteredTrips;

    return tripsToDisplay.length > 0
      ? tripsToDisplay.map((trip) => (
          <Link key={trip.id} to={`${trip.id}`}>
            <div
              className={`trip-fc ${
                trip.printStatus === 'COMPLETE' && 'trip-printed'
              }`}
            >
              <span>🏪</span>
              <div className="content-wrapper">
                {trip.label}
                {trip.printStatus === 'COMPLETE' && (
                  <img src={validCheck} alt="valid check icon" />
                )}
              </div>
            </div>
          </Link>
        ))
      : t('views.print_label_fc.no_trip_found');
  };

  const handlePrevDay = () => {
    if (currentDay > 0) setCurrentDay(currentDay - 1);
  };

  const handleNextDay = () => {
    if (currentDay < days.length - 1) setCurrentDay(currentDay + 1);
  };

  const getDayName = (date) => {
    const options = { weekday: 'long' };
    return new Date(date)
      .toLocaleDateString(i18n.language, options)
      .toUpperCase();
  };

  const renderContent = () => {
    if (status === 'loading') {
      return <Loader />;
    }

    return (
      <>
        <span className="day-navigation-header">
          {t('views.print_station.pick_day')}
        </span>
        <div className="day-navigation">
          <div
            className={`navigation-button ${
              currentDay === 0 ? 'first-day' : ''
            }`}
            onClick={currentDay === 0 ? null : handlePrevDay}
          >
            {'<'}
          </div>
          <div>{getDayName(days[currentDay])}</div>
          <div
            className={`navigation-button ${
              currentDay === days.length - 1 ? 'last-day' : ''
            }`}
            onClick={currentDay === days.length - 1 ? null : handleNextDay}
          >
            {'>'}
          </div>
        </div>
        <div style={{ alignSelf: 'center' }}>
          <div className="toggle-trips-sort">
            <button
              type="button"
              onClick={() => setIsReverseOrder(!isReverseOrder)}
            >
              {isReverseOrder
                ? t('views.print_label_fc.show_normal_order')
                : t('views.print_label_fc.show_reverse_order')}
            </button>
          </div>
        </div>
        {layout()}
      </>
    );
  };

  return <div className="trips-fc-container">{renderContent()}</div>;
}

export default PrintLabelsFc;
