/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getGroceriesByPackage } from './fillv2.actions';
import { tryTranslateError } from '@/helpers/helpers';

const initialState = {
  data: {},
  status: 'idle',
  error: null,
  fillv2Step: 'starting'
};

const fillv2Slice = createSlice({
  name: 'fillv2',
  initialState,
  reducers: {
    getProductsTofill: (state, { payload }) => {
      console.log(payload);
    },
    resetFillv2ProductsData: (state) => {
      state.data = {};
      state.fillv2Step = 'starting';
    },
    updatefillStep: (state, action) => {
      state.fillv2Step = action.payload.fillv2Step;
    }
  },
  extraReducers(builder) {
    builder
      .addCase(getGroceriesByPackage.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getGroceriesByPackage.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        // state.productToScan = payload;
        state.data = payload;
      })
      .addCase(getGroceriesByPackage.rejected, (state, { payload }) => {
        state.status = 'failed';
        state.error = tryTranslateError(payload);
        state.data = {};
      });
  }
});

function selectProducts(state) {
  return state.fillProducts.data;
}

export const { getProductsTofill, resetFillv2ProductsData, updatefillStep } =
  fillv2Slice.actions;

export default fillv2Slice.reducer;
